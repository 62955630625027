<template>
  <div class="attachments">
    <Header
      back="返回"
      title="财务列表"
      index="首页"
      titleOne="财务管理"
      titleTwo="财务列表"
      beforeTitle="去开票"
    />
    <div class="content">
      <h1>开票信息</h1>
      <el-form
        :rules="formRule"
        ref="formRule"
        :model="form"
        :label-position="labelPosition"
      >
        <el-form-item label="企业名称">
          <el-input v-model="form.company_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="纳税人识别号">
          <el-input v-model="form.taxpayer" disabled></el-input>
        </el-form-item>
        <el-form-item label="发票类型">
          <el-select v-model="form.invoice_type" placeholder="请选择" disabled>
            <el-option label="普通发票" :value="1">普通发票</el-option>
            <el-option label="增值税发票" :value="2">增值税发票</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开户行">
          <el-input v-model="form.bank" disabled></el-input>
        </el-form-item>
        <el-form-item label="开户账号">
          <el-input v-model="form.account" disabled></el-input>
        </el-form-item>
        <el-form-item label="金额(元)">
          <el-input v-model="form.momey" disabled></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="form.address" disabled></el-input>
        </el-form-item>
        <el-form-item label="电话">
          <el-input v-model="form.tel" disabled></el-input>
        </el-form-item>
        <el-form-item label="货物或应税劳务、服务名称">
          <el-input v-model="form.service_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="开票基准">
          <el-select v-model="form.billing_basic" placeholder="请选择" disabled>
            <el-option label="基于合同" :value="1">基于合同</el-option>
            <el-option label="基于项目" :value="2">基于项目</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否开票">
          <el-switch
            v-model="form.status"
            @click.native="getTnvoice()"
          ></el-switch>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      labelPosition: "left",
      form: {
        company_name: "",
        taxpayer: "",
        invoice_type: "",
        bank: "",
        account: "",
        momey: "",
        address: "",
        tel: "",
        service_name: "",
        billing_basic: "",
        status: false,
      },
      formRule: {},
    };
  },
  computed: {
    meber_id() {
      return this.$route.query.id;
    },
  },
  mounted() {
    if (this.meber_id) {
      this.axios
        .get("/api/contract/view_invoice", {
          params: {
            contract_project_id: this.meber_id,
          },
        })
        .then((res) => {
          this.form = res.data;
          this.id = res.data.id;
        });
    }
  },
  methods: {
    getTnvoice() {
      this.$confirm("请再次确定，是否已开票？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios.post("/api/finance/invoice", {
            id: this.id,
          });
          this.form.status = true;
          this.$message({
            type: "success",
            message: "开票成功!",
          });
        })
        .catch(() => {
          this.form.status = false;
          this.$message({
            type: "info",
            message: "已取消开票",
          });
        });
    },
  },
};
</script>

<style scoped="scoped">
::v-deep .el-form-item__label {
  width: 250px !important;
}

::v-deep .el-form-item__content {
  margin-left: 250px !important;
}

::v-deep .el-input__inner {
  border: none !important;
}
</style>
